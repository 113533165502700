<template>
  <div>
    <!-- SINGLE PROPERTY-->

    <div
      class="max-w-4xl mx-auto container leading-7 text-center bg-white text-sm text-black m-16"
    >
      <h1 class="text-2xl text-gray-800 uppercase ">tales from the ocean</h1>
      <p class="md:px-40 px-6 text-gray-800 text-sm pt-8">
        Unravel thousand of stories right from the inside the Maldivian blue.
        Storeis that are both charming and informative that will provote your
        senses and show you the true maldives.
      </p>
    </div>

    <div class="w-full max-w-6xl mx-auto  mx-auto bg-white mt-8 md:mt-18 mb-20">
      <div v-for="blogPost in blogPosts" :key="blogPost._id" class="villa-card flex md:flex-row  flex-wrap ">
        <div
          class="villa-card--text-container border w-full md:w-1/2 bg-white text-left px-12 md:px-28 py-20 text-gray-200"
        >
         <router-link :to="`/tales/${blogPost.title.toLowerCase().split(' ').join('-')}`"> <h1 class="text-black text-2xl font-medium text-left  pb-6">
          {{blogPost.title}}          </h1> </router-link>



            <div class="relative">
          <div
            v-html="blogPost.content"
            class="text-sm text-black leading-7 post-description  items-center"
          ></div>
          <div class="post-description-overlay"></div>
        </div>


 

        <router-link :to="`/tales/${blogPost.title.toLowerCase().split(' ').join('-')}`">  <button
            class="text-gray-500 border-gray-500 border hover:opacity-80 text-xs uppercase py-4 px-6 mt-7"
          >
            READ MORE
          </button> </router-link>
        </div>
        <div
        :style="{ backgroundImage: `url(${$api}/uploads/${blogPost.featuredImage})` }"
           class="villa-card--image-container bg-cover flex flex-col relative w-full md:w-1/2 bg-white text-black"
        ></div>
      </div>

      <!-- <div class="villa-card flex md:flex-row  flex-wrap ">
        <div
          class="villa-card--text-container border w-full md:w-1/2 bg-white text-left px-12 md:px-28 py-20 text-gray-200"
        >
          <h1 class="text-black text-2xl font-medium text-left  pb-6">
            There Is Something That Has Always Been One Thing Or The Other
          </h1>

          <p class="text-sm text-black leading-7">
            Relax and regenerate the body and the soul thanks to luxurious
            rituals by Maldivian tradition. Share beauty and wellness experience
            with your loved ones at the niyama spa..
          </p>

          <button
            class="text-gray-500 border-gray-500 border hover:opacity-80 text-xs uppercase py-4 px-6 mt-7"
          >
            READ MORE
          </button>
        </div>
        <div
          style="background-image:url(https://source.unsplash.com/yERyCOOT8i8);"
          class="villa-card--image-container flex flex-col relative w-full md:w-1/2 bg-white text-black"
        ></div>
      </div> -->

      <div class="flex justify-center mx-auto px-4 m-10">
        <!-- PAGINATION-->
        <!-- PAGINATION-->
        <Pagination
          v-if="paginationData"
          :paginationData="paginationData"
          url="/tales/page/"
        ></Pagination>
      </div>
    </div>
  </div>
</template>


<script>
  import Pagination from "@/components/base/Pagination";

export default {
  name: "Tales",
 
  data() {
    return {
      currentPage: this.$route.params.pageid || 1,
      perPage: this.$route.query.limit || 4,
      paginationData: null,
      blogPosts: [],
    
    };
  },
 
  mounted() {
     this.getBlogPost();
  },
  methods: {

async getBlogPost() {
      try {
        let uri = `${this.$api_v1}/blog?page=${this.currentPage}&limit=${this.perPage}`;

        const res = await this.$axios.get(uri);

        if (res.status === 200) {
          this.blogPosts = res.data.posts;
          // console.log(res.data);
           this.paginationData = res.data.paginationData;
          
         }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
   watch: {
    async $route(to, from) {
      from;
      if (to.name == "Tales") {
        await this.getBlogPost();
      }
      if (to.name == "TalesP") {
        this.currentPage = this.$route.params.pageid;
        console.log(this.currentPage);
        await this.getBlogPost();
      }
    },
  },
   components: {
    Pagination,
  },
 
};
</script>

<style lang="scss" scoped>
.villa-card {
  &:nth-child(even) {
    .villa-card--image-container {
      order: -1;
    }
  }
}


.post-description {
  max-height: 160px;
  overflow: hidden;
}
.post-description-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 70%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>  

 