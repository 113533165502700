<template>
  <ul
    class="table mt-5 border-collapse text-center bg-white mx-auto shadow-sm"
    v-if="pagination_data.totalPages > 1"
  >
    <template v-if="pagination_data.currentPage > pagination_data.startPage">
      <li
        v-if="pagination_data.startPage !== 1"
        class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
      >
        <router-link :to="url + 1">
          <button
            class="
              hover:bg-primary
              w-full
              h-full
              transition
              duration-100
              ease-in-out
              focus:ring-2
              focus:ring-blue-500
              focus:outline-none
              focus:ring-opacity-50
            "
          >
            «
          </button>
        </router-link>
      </li>
      <li
        class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
      >
        <router-link :to="url + (pagination_data.currentPage - 1)">
          <button
            class="
              hover:bg-primary hover:text-white
              w-full
              h-full
              transition
              duration-100
              ease-in-out
              focus:ring-2
              focus:ring-blue-500
              focus:outline-none
              focus:ring-opacity-50
            "
          >
            ‹
          </button>
        </router-link>
      </li>
    </template>
    <li
      v-for="p in pagination_data.pages"
      :key="p"
      class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
    >
      <router-link :to="url + p">
        <button
          v-if="p == pagination_data.currentPage"
          class="
            bg-primary
            w-full
            h-full
            text-white
            hover:bg-primary
            transition
            duration-100
            ease-in-out
            focus:ring-2
            focus:ring-blue-500
            focus:outline-none
            focus:ring-opacity-50
          "
        >
          {{ p }}
        </button>
        <button
          v-else
          class="
            hover:bg-primary hover:text-white
            w-full
            h-full
            transition
            duration-100
            ease-in-out
            focus:ring-2
            focus:ring-blue-500
            focus:outline-none
            focus:ring-opacity-50
          "
        >
          {{ p }}
        </button>
      </router-link>
    </li>
    <template v-if="pagination_data.currentPage < pagination_data.endPage">
      <li
        class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
      >
        <router-link :to="url + (pagination_data.currentPage + 1)">
          <button
            class="
              hover:bg-primary hover:text-white
              w-full
              h-full
              transition
              duration-100
              ease-in-out
              focus:ring-2
              focus:ring-blue-500
              focus:outline-none
              focus:ring-opacity-50
            "
          >
            ›
          </button>
        </router-link>
      </li>
      <li
        v-if="pagination_data.endPage !== pagination_data.totalPages"
        class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
      >
        <router-link :to="url + pagination_data.totalPages">
          <button
            class="
              hover:bg-primary
              w-full
              h-full
              transition
              duration-100
              ease-in-out
              focus:ring-2
              focus:ring-blue-500
              focus:outline-none
              focus:ring-opacity-50
            "
          >
            »
          </button>
        </router-link>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: ["paginationData", "url", "maxPages"],
  data() {
    return {
      pagination_data: [],
      max_Pages: this.maxPages ? this.maxPages : 5,
    };
  },
  mounted() {
    if (this.paginationData) {
      const pagination_data = this.paginate(this.paginationData);
      this.pagination_data = pagination_data;
    }
  },
  watch: {
    paginationData() {
      if (this.paginationData) {
        const pagination_data = this.paginate(this.paginationData);
        this.pagination_data = pagination_data;
      }
    },
  },
  methods: {
    paginate(paginationData) {
      let totalItems = paginationData.total;
      let currentPage = paginationData.currentPage;
      let pageSize = paginationData.perPage;
      let maxPages = this.max_Pages;
      let totalPages = paginationData.totalPages;
      let startPage,
        endPage,
        maxPagesBeforeCurrentPage,
        maxPagesAfterCurrentPage,
        startIndex,
        endIndex,
        pages;

      //   console.log(totalItems, currentPage, pageSize, maxPages, totalPages);

      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      if (totalPages <= maxPages) {
        startPage = 1;
        endPage = totalPages;
      } else {
        maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      startIndex = (currentPage - 1) * pageSize;
      endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );

      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
      };
    },
  },
};
</script>
